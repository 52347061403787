import React, { useState } from "react"

import Layout from "../components/Layout"
import Page from "../components/Layout/page"
import GoogleMaps from "../components/GoogleMaps"
import ContactForm from "../components/Forms/contact"

const Contact = (props) => {
  const [showInfoWindow, setShowInfoWindow] = useState(true)

  return (
    <Layout location={props.location}>
      <Page id="5" />
      <GoogleMaps
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div style={{ height: `500px`, marginBottom: `2rem` }} />
        }
        mapElement={<div style={{ height: `100%` }} />}
        mapCenter={{ lat: -34.493091, lng: 150.892929 }}
        isMarkerShown
        markerCenter={{ lat: -34.493091, lng: 150.892929 }}
        zoom={16}
        toggleInfoWindow={() => {
          setShowInfoWindow(!showInfoWindow)
        }}
        showInfoWindow={showInfoWindow}
        infoWindowData={
          <>
            <h5 className="googlemaps-heading">South Coast Auctions</h5>
            <p className="googlemaps-text">
              205a Shellharbour Road
              <br />
              Kemblawarra, NSW, 2505
            </p>
          </>
        }
      />
      <ContactForm />
    </Layout>
  )
}

export default Contact
