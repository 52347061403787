import React, { useState } from "react"
import Axios from "axios"
import Recaptcha from "react-recaptcha"

import Message from "../Message"
import Feedback from "../Forms/feedback"
import Button from "../Button"

import "./contact.scss"

const formsAPI = process.env.API_FORMS
const recaptchaKey = process.env.GOOGLE_RECAPTCHA_KEY
let recaptchaInstance

const ContactForm = () => {
  const [errors, setErrors] = useState({})
  const [message, setMessage] = useState({})
  const [canSubmit, setCanSubmit] = useState(true)
  const [formData, setFormData] = useState({})

  const validateInputs = () => {
    let ok = true
    setErrors({})
    if (!formData.name) {
      setErrors(prevState => ({
        ...prevState,
        name: "Please enter a valid name",
      }))
      ok = false
    }
    if (!formData.reCaptcha) {
      setErrors(prevState => ({
        ...prevState,
        reCaptcha: "Please verity you are a human",
      }))
      ok = false
    }
    if (!isEmail(formData.email)) {
      setErrors(prevState => ({
        ...prevState,
        email: "Please enter a valid e-mail",
      }))
      ok = false
    }
    return ok
  }

  const isEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (validateInputs() === false) {
      return
    } else {
      setCanSubmit(false)
      setMessage({})
      Axios.post(`${formsAPI}/contact`, {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        enquiry: formData.enquiry,
        reCaptcha: formData.reCaptcha,
      })
        .then(() => {
          setCanSubmit(true)
          setMessage({
            text: "Message Sent",
            type: "success",
          })
          resetRecaptcha()
        })
        .catch(error => {
          setCanSubmit(true)
          setMessage({
            text: error.response.data.message,
            type: "danger",
          })
          resetRecaptcha()
        })
    }
  }

  const resetRecaptcha = () => {
    const newFormData = formData
    delete newFormData.reCaptcha
    setFormData(newFormData)
    recaptchaInstance.reset()
  }

  const handleChange = e => {
    e.persist()
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const setReCaptcha = response => {
    setFormData(prevState => ({ ...prevState, reCaptcha: response }))
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-lg-4 form-col-1">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="name"
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                id="name"
                aria-describedby="nameHelp"
                placeholder="Name"
              />
              <Feedback message={errors.name} />
            </div>
            <div className="form-group">
              <input
                onChange={handleChange}
                name="email"
                className={
                  errors.email ? "form-control is-invalid" : "form-control"
                }
                id="email"
                aria-describedby="emailHelp"
                placeholder="E-Mail"
              />
              <Feedback message={errors.email} />
            </div>
            <div className="form-group">
              <input
                onChange={handleChange}
                name="phone"
                className="form-control"
                id="phone"
                aria-describedby="phoneHelp"
                placeholder="Phone"
              />
            </div>
          </div>
          <div className="col-lg-4 form-col-2">
            <div className="form-group">
              <textarea
                onChange={handleChange}
                name="enquiry"
                className="form-control"
                id="enquiry"
                rows="6"
                placeholder="Message"
              ></textarea>
            </div>
            <div className="form-group">
              <Recaptcha
                ref={e => (recaptchaInstance = e)}
                sitekey={recaptchaKey}
                render="explicit"
                onloadCallback={() => {}}
                verifyCallback={response => {
                  setReCaptcha(response)
                }}
              />
              <Feedback message={errors.reCaptcha} />
            </div>
            <div className="form-group">
              <Message type={message.type} message={message.text} />
            </div>
            <div className="form-group">
              <Button
                btnStyle="sca-primary"
                customClass="btn-icon-right"
                type="submit"
                disabled={!canSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ContactForm
