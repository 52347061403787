import React from "react"

const Feedback = ({ message }) => {
    return (
        <div className="form-invalid">
            {message}
        </div>
    )
}

export default Feedback